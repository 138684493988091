const active = '-active',
      trigger = '#js-toggleNavTrigger',
      target  = '#js-toggleNav',
      header = '#js-header';
$(trigger).on('click', (event) => {
  $(header).toggleClass(active);
  $(event.currentTarget).toggleClass(active);
  $(target).toggleClass(active);

   return false;
});
