const active = '-active',
      trigger = '.js-targetTrigger',
      target  = '.js-target',
      status  = '.js-targetStatus';
$(trigger).on('click', (event) => {
  $(event.currentTarget).children(status).toggleClass(active);
  $(event.currentTarget).next(target).slideToggle();

   return false;
});
