$(function() {

  const toTop = $('#js-toTop');
  toTop.hide()
  $(window).scroll(function () {
    if ($(this).scrollTop() > 200) {
      toTop.fadeIn()
    } else {
      toTop.fadeOut()
    }
  });

});
