const active = '-active',
      trigger = '.js-pid0-products-trigger',
      target  = '.js-pid0-products';
$(trigger).on('click', (event) => {
  let index = $(trigger).index(event.currentTarget);
  $(trigger).removeClass(active);
  $(event.currentTarget).addClass(active);
  $(target).removeClass(active);
  $(target).eq(index).addClass(active);

   return false;
});
