const incomeTable = '#js-income-table',
      income = '.js-income';

$(incomeTable).on('input', income, function () {
  let total = 0;

  $(incomeTable + ' ' + income).each(function () {
    let getIncome = $(this).val();
    if ($.isNumeric(getIncome)) {
      total += parseFloat(getIncome);
    }
  });

  $("#js-total").html(total);
  $("#input-total").val(total);
});
